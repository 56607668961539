import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/Layout"

const Privacy = ({ location }) => {
  const lang = "ja"

  // Page setting
  const pageTitle = "プライバシーポリシー"
  const pageSlug = "privacy"
  const pageKeywords = [`プライバシーポリシー`, `Privacy Policy`]
  const pageDescription =
    "箕澤屋は信州伊那谷の箕輪町にある築150年の古民家です。"
  const pageImage = ""
  const email = "info@misawaya.net"

  return (
    <Layout
      location={location}
      lang={lang}
      pageTitle={pageTitle}
      pageSlug={pageSlug}
      pageKeywords={pageKeywords}
      pageDescription={pageDescription}
      pageImage={pageImage}
    >
      <div className="page-title-wrapper">
        <h1 className="page-title container-narrow">{pageTitle}</h1>
      </div>
      <article className="content-area privacy-content">
        <div className="container-narrow">
          <div className="content__item">
            <p style={{ marginBottom: "1rem" }}>
              当サイト（
              <Link to="/" className="link-ul">
                https://misawaya.org/
              </Link>
              ）は、個人情報を取り扱うにあたり、個人情報の保護に関する法令等を遵守するとともに、本プライバシーポリシーを遵守いたします。
            </p>
            <p>
              なお、必要に応じて、本プライバシーポリシーを改定する場合があります。その際には、最新のプライバシーポリシーを本ページに掲載いたします。
            </p>
          </div>
          <h2 className="content__head">個人情報の収集</h2>
          <div className="content__item">
            <p>
              当サイトは、サイト運営、及び箕澤屋の活動に必要な範囲で個人情報を収集いたします。
            </p>
          </div>
          <h2 className="content__head">個人情報の管理・利用目的</h2>
          <div className="content__item">
            <p style={{ marginBottom: "1rem" }}>
              収集した個人情報（メールアドレスや名前等）は、下記の目的のために利用いたします。
              <br />
              1. お問い合わせ返信
              <br />
              2. ニュースレター配信
            </p>
            <p>
              なお、知り得た個人情報を第三者に開示することは、本人のご了解がある場合、または公的機関からの書面をもった請求以外にいたしません。
            </p>
          </div>
          <h2 className="content__head">アクセス解析ツールについて</h2>
          <div className="content__item">
            <p>
              当サイトでは、アクセス解析ツール{" "}
              <a
                href="https://marketingplatform.google.com/intl/ja/about/analytics/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-ul"
              >
                Google Analytics
              </a>
              を利用しています。Google
              Analyticsでは、トラフィックデータの収集のためにCookieを使用し、個人を特定する情報を含まずにログを収集します。
            </p>
            <p>
              Google Analyticsの利用規約に関して、詳しくは
              <a
                href="https://marketingplatform.google.com/about/analytics/terms/jp/"
                target="_blank"
                rel="noopener noreferrer"
                className="link-ul"
              >
                Googleアナリティクス利用規約
              </a>
              をご覧ください。
            </p>
          </div>

          <h2 className="content__head">連絡先</h2>
          <div className="content__item">
            <p>
              本プライバシーポリシーに関するお問い合わせにつきましては、下記へご連絡ください。
              <br />
              <a href={"mailto:" + email} className="link-ul">
                {email}
              </a>
            </p>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default Privacy
